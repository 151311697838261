define("discourse/plugins/kistaKoord/kista-koord/controllers/koord-suspend", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    keyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    onShow() {
      console.log("ISACTIVE", window.xdata.isactive);
      this.setProperties({
        // group: this.fullgroupname,
        activate: !window.xdata.isactive,
        name: window.xdata.name
      });
      this.setProperties({
        server: this.get("servers.firstObject.value")
      });
    },
    genpw() {
      return Math.random().toString(36).slice(-8);
    },
    submitDisabled(email) {
      return (0, _utils.isEmpty)(email) || !/^[-._+a-z0-9]+@[-._a-z0-9]{2,}\.[a-z]{2,}$/i.test(email); // || !/^2[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(validto);
    },
    actions: {
      resend() {
        const btnTxt = this.buttonText ? ` label="${this.buttonText}"` : "";
        console.log(this.message);
        let validto = this.vdt instanceof moment ? this.vdt.toDate() : new Date(this.vdt);
        this.retfunc({
          email: this.email,
          message: this.message,
          validto: validto
        });
        // this.toolbarEvent.addText(
        //   `[wrap=bbb Titel="${this.title}"${btnTxt} pp="${this.genpw()}-${this.genpw()}"${this.hasOwnProperty("server")&&this.server!=1?' sr="'+this.server+'"':''}][/wrap]`
        // ); // mobileIframe="${ this.mobileIframe }" desktopIframe="${this.desktopIframe}"
        this.send("closeModal");
      },
      aactivate() {
        this.retfunc(1);
        this.send("closeModal");
        // Route.refresh();
      },
      adeactivate() {
        this.retfunc(0);
        this.send("closeModal");
        // Route.refresh();
        // this.refresh();
      },
      acancel() {
        this.send("closeModal");
      }
    }
  }, [["method", "submitDisabled", [(0, _decorators.default)("email")]]]));
});