define("discourse/plugins/kistaKoord/discourse/api-initializers/header-add-group", ["exports", "discourse/components/d-button", "discourse/lib/api", "@glimmer/tracking", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _api, _tracking, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TrackObject {
    static #_ = (() => dt7948.g(this.prototype, "icon", [_tracking.tracked], function () {
      return "users";
    }))();
    #icon = (() => (dt7948.i(this, "icon"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "title", [_tracking.tracked], function () {
      return "";
    }))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "flair", [_tracking.tracked]))();
    #flair = (() => (dt7948.i(this, "flair"), void 0))();
  }
  var _default = _exports.default = (0, _api.apiInitializer)("1.0.0", api => {
    const currentUser = api.getCurrentUser();
    if (currentUser) {
      const router = api.container.lookup("service:router");
      const nav = () => router.transitionTo('group', currentUser.primary_group_name);
      const tracked = new TrackObject();
      tracked.title = currentUser.primary_group_name;
      api.headerIcons.add("lnw-group-header-icon", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
      /*
        
            <li id="group-headerbutton">
              <DButton class="icon btn-flat" @icon={{tracked.icon}} @action={{nav}} @translatedTitle={{tracked.title}}>
                {{#if tracked.flair}}<img src="{{tracked.flair}}" class="avatar">{{/if}}
              </DButton>
            </li>
          
      */
      {
        "id": "EdN8AOvW",
        "block": "[[[1,\"\\n      \"],[10,\"li\"],[14,1,\"group-headerbutton\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"icon btn-flat\"]],[[\"@icon\",\"@action\",\"@translatedTitle\"],[[32,1,[\"icon\"]],[32,2],[32,1,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[41,[32,1,[\"flair\"]],[[[10,\"img\"],[15,\"src\",[29,[[32,1,[\"flair\"]]]]],[14,0,\"avatar\"],[12],[13]],[]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[],false,[\"if\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/kistaKoord/discourse/api-initializers/header-add-group.js",
        "scope": () => [_dButton.default, tracked, nav],
        "isStrictMode": true
      }), (0, _templateOnly.default)(undefined, undefined)), {
        before: "user-menu"
      });
      fetch(`/g/${currentUser.primary_group_name}.json`).then(r => r.json()).then(js => {
        tracked.title = js.group.full_name;
        tracked.flair = js.group.flair_url;
        tracked.icon = null;
      });
    }
  });
});